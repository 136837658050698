import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { twJoin } from 'tailwind-merge';

import Box from 'shopper/components/Box';
import Icon from 'shopper/components/Icon';
import { TabNav, TabNavItem } from 'shopper/components/TabNav';
import Text from 'shopper/components/Text';

import { sendEvent } from 'lib/gtag';
import { stripQs } from 'lib/request';

import HOME_OFFERS_LIST_TABS from 'constants/homeOffersListTabs';

const GA_EVENT_CATEGORY = 'timeline';

const TimelinesNavigation = ({ className }) => {
  const router = useRouter();

  const pathname = stripQs(router.asPath);

  return (
    <Box className={twJoin('rounded-none', className)}>
      <TabNav className="w-full justify-center border-none">
        {HOME_OFFERS_LIST_TABS.map(({ id, title, href, iconName }) => {
          const isActive = href === pathname;

          return (
            <NextLink key={id} href={href} passHref>
              <TabNavItem
                key={id}
                active={isActive}
                as="a"
                className="w-full p-0"
                size="size2"
                onClick={() => {
                  sendEvent({
                    action: `sheet_${id}_offers`,
                    category: GA_EVENT_CATEGORY,
                  });
                }}
              >
                <div className="flex flex-col items-center justify-center gap-1">
                  <Icon
                    className={
                      isActive && 'text-primary-300 dark:text-primary-300'
                    }
                    name={iconName}
                    size="size1"
                  />
                  <Text
                    className={twJoin(
                      'mb-2 text-center leading-[17px]',
                      isActive && 'text-primary-300 dark:text-primary-300'
                    )}
                    bold
                  >
                    {title}
                  </Text>
                </div>
              </TabNavItem>
            </NextLink>
          );
        })}
      </TabNav>
    </Box>
  );
};

export default TimelinesNavigation;
